import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>bonkassy</h1>
      <p class='c2'><a href='http://naprotivnikako.tumblr.com'>Naprotiv Nikako <em>Tumblr</em></a></p>
      <p class='c1'><a href='http://bolonjez.tumblr.com'>Bolonjez <em>Tumblr</em></a></p>
      <p class='c3'><a href='http://instagram.com/bonkasica'>Bonkasica <em>Instagram</em></a></p>
      <p class='c4'><a href='http://www.praznine.si/en/749/'>Dear Architecture <em>Praznine</em></a></p>
      <p class='c1 spamoji'><a href='http://spamoji.xyz'><em>Spamoji</em></a></p>
      <p class='c5 mail'><a href='mailto:ministarstvo.interneta@gmail.com'>ministarstvo.interneta@gmail.com</a>
      </p>
    </div>
  );
}

export default App;
